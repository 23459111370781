<template>
  <div>
    <DeleteItemModal
       v-if="showModal"
       :title="$t('customer.deleteCustomerModal.title')"
       :description="$t('customer.deleteCustomerModal.description')"
       @handleCloseModal="handleCloseModal"
       @handleConfirm="deleteCustomer"
    />
    <div class="relative" v-if="!loading">
      <DataTableSearch @handleSearchTable="handleSearchTable" />
        <Table
          @handleSort="handleSort"
          @handleOpenDeleteCustomerModal="handleOpenDeleteCustomerModal"
          :dataTable="dataTable"
        />
      <Pagination :count="getCustomersCount" @handlePaginate="handlePaginate" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DeleteItemModal from '@/components/Modals/DeleteItemModal/DeleteItemModal';
import DataTableSearch from '@/components/DataTableSearch/DataTableSearch';
import Pagination from '@/components/Main/Pagination/Pagination';
import handleTableSort from '@/helpers/handleTableSort';
import EditActions from '@/components/Table/EditActions/EditActions';
import TableHead from '@/components/Customer/Table/TableHead/TableHead';
import TableBody from '@/components/Customer/Table/TableBody/TableBody';
import Table from '@/components/Customer/Table/Table';
import { EventBus } from '@/eventBus/event-bus';

export default {
  name: 'CustomersTable',
  components: {
    Table,
    TableBody,
    EditActions,
    Pagination,
    DataTableSearch,
    DeleteItemModal,
    TableHead
  },
  data() {
    return {
      count: 0,
      showModal: false,
      data: [],
      dataTable: [],
      loading: true,
      searchValue: '',
      searchTimer: null,
      selectedCustomerId: '',
      page: 1,
      sortBy: 'name',
      sortDesc: 0
    }
  },
  computed: {
    ...mapGetters([
        'customers',
        'getCustomersCount'
    ])
  },
  watch: {
    customers(newValue) {
      this.data = newValue;
    },
    data(newValue) {
      this.count = newValue.length;
      this.dataTable = newValue.slice(0, 30);
      this.loading = false;
    }
  },
  mounted() {
    this.getCustomers({ page: this.page, search: this.searchValue, sortBy: this.sortBy, sortDesc: +this.sortDesc });
  },
  methods: {
    ...mapActions(['getCustomers', 'fetchDeleteCustomer']),
    handleSort(key, sorting) {
      this.sortBy = key;
      this.sortDesc = !sorting;

      this.getCustomers({ page: this.page, search: this.searchValue, sortBy: this.sortBy, sortDesc: +this.sortDesc });
    },
    handleSearchTable(value) {
      this.searchValue = value;
      this.page = 1;
      EventBus.$emit('resetPage');

      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
        this.getCustomers({ page: this.page, search: this.searchValue, sortBy: this.sortBy, sortDesc: +this.sortDesc });
      }, 500);
    },
    handlePaginate(page, defaultPageSlice) {
      this.page = defaultPageSlice / (defaultPageSlice - page);
      this.getCustomers({ page: this.page, search: this.searchValue, sortBy: this.sortBy, sortDesc: +this.sortDesc });
    },
    handleOpenDeleteCustomerModal(customerId) {
      this.selectedCustomerId = customerId;
      this.showModal = true;
    },
    handleCloseModal() {
      this.showModal = false;
    },
    deleteCustomer() {
      this.fetchDeleteCustomer({ id: this.selectedCustomerId }).then(response => {
        this.toastMessage(response.message, 'success');
      }).catch(error => {
        this.toastMessage(error.message, 'error');
      });
      this.showModal = false;
      this.data = this.data.filter(product => product.id !== this.selectedCustomerId);
    },
    toastMessage(message, type) {
      this.$toast.open({ message, type });
    }
  }
}
</script>

<style scoped>

</style>
