<template>
  <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1">
    <div class="col-span-1">
      <AssigneeUsers
          @handleChangeAssigneeUser="$emit('handleChangeAssigneeUser', $event)"
          :defaultValue="String(form.assignee_id)"
          :editable="editable"
      />
      <AssigneeInvoiceConnectors
      @handleChangeAssigneeConnectorId="$emit('handleChangeAssigneeConnectorId', $event)"
        :defaultValue="String(form.connector_id)"
      :editable="editable"
      />
    </div>
    <div class="col-span-1">
      <AttachmentInputField
          @handleAddAttachments="$emit('handleAddAttachments', $event)"
          @deleteAttachmentFile="$emit('deleteAttachmentFile', $event)"
          :pageUpdateAction="pageUpdateAction"
          :files="form.files"
          :ownerId="form.id"
          :prefix="prefix"
      />
    </div>
    <div class="col-span-1">
      <ShareSpace
          :sharedSpaces="form.shareSpaces"
          @deleteShareSpace="$emit('deleteShareSpace', $event)"
          @handleAddShareSpaces="$emit('handleAddShareSpaces', $event)"
          @updateShareSpace="$emit('updateShareSpace', $event)"
          :pageUpdateAction="pageUpdateAction"
      />
    </div>
  </div>
</template>

<script>
import AttachmentInputField from '@/components/Main/AttachmentFiles/AttachmentInputField/AttachmentInputField';
import ShareSpace from '@/components/Main/ShareSpace/ShareSpace';
import AssigneeUsers from '@/components/SelectOptions/AssigneeUsers/AssigneeUsers';
import AssigneeInvoiceConnectors from '@/components/SelectOptions/AssigneeInvoiceConnectors/AssigneeInvoiceConnectors';
import { mapGetters } from 'vuex';

export default {
  name: 'RightAside',
  components: {
    AssigneeInvoiceConnectors,
    AssigneeUsers,
    ShareSpace,
    AttachmentInputField
  },
  props: {
    form: {
      type: Object,
      required: true
    },
    prefix: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean,
      required: true
    },
    pageUpdateAction: {
      type: Boolean,
      'default': true
    }
  }
}
</script>

<style scoped>

</style>
