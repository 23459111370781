<template>
  <tr @mouseover="mouseOver(true)" @mouseleave="mouseLeave(false)">
    <td class="px-6 py-3 max-w-0 w-full whitespace-nowrap text-sm font-medium text-gray-900">
      <div class="flex items-center space-x-3">
        <router-link :to="{ name: 'customer-show', params: { id: customer.id } }" class="truncate hover:text-gray-600">
          <span>{{ customer.name }}</span>
        </router-link>
      </div>
    </td>
    <td class="px-8 py-3 text-sm text-gray-500 md:table-cell whitespace-nowrap truncate block text-right">
      <span  class="pr-6">{{ customer.address }}</span>
    </td>
    <td class="hidden xl:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right">
      {{ customer.contact}}
    </td>
    <td class="hidden xl:table-cell px-7 py-3 whitespace-nowrap truncate block text-sm text-gray-500">
      <div class="flex justify-end">
        <TableRowUserAvatar
            :avatar="customer.assignee ? customer.assignee.avatar : null"
            :name="customer.assignee ? customer.assignee.name : null"
            widthFull
        />
      </div>
    </td>
    <td class="pr-4">
      <EditActions
          v-if="showEditable || (selectedItem === String(customer.id))"
          :item="customer"
          itemType="customer"
          @handleOpenDeleteItemModal="handleOpenDeleteItemModal"
          @handleSelectItem="handleSelectItem"
      />
    </td>
  </tr>
</template>

<script>
import EditActions from '@/components/Table/EditActions/EditActions';
import TableRowUserAvatar from '@/components/TableRowUserAvatar/TableRowUserAvatar';

export default {
name: 'Row',
  components: {
    TableRowUserAvatar,
    EditActions
  },
  props: {
    customer: {
      type: Object,
      required: true
    },
    selectedItem: String
  },
  data() {
    return {
      showEditable: false,
      isOpenEditAction: false
    }
  },
  methods: {
    mouseOver(showEditable) {
      this.showEditable = showEditable;
      this.isOpenEditAction = false;
    },
    mouseLeave(showEditable) {
      this.showEditable = showEditable;
      this.isOpenEditAction = false;
    },
    handleSelectItem(selectedItem) {
      this.$emit('handleSetSelectedItem', selectedItem);
      this.isOpenEditAction = true;
    },
    handleOpenDeleteItemModal(customerId) {
      this.$emit('handleOpenDeleteItemModal', customerId);
    }
  }
}
</script>

<style scoped>

</style>
