<template>
  <thead class="border-t border-gray-200 border-b border-gray-200 bg-gray-50 text-left text-xs text-gray-500 uppercase tracking-wider">
  <tr>
    <th class="whitespace-nowrap xxl:w-3/7 2xl:w-300p px-6 py-3">
      <div class="flex items-end cursor-pointer" @click="handleSort('name')">
        <div class="relative flex items-center flex-start pr-6 font-medium">
          {{ $t('customer.fields.title') }}
          <ArrowSolidIcon v-if="selectedSortingKey === 'name'" :arrowUp="!arrowSortUp['name']" />
        </div>
      </div>
    </th>
    <th class="whitespace-nowrap sm:w-300p xs:w-300p px-8">
      <div class="flex justify-end cursor-pointer" @click="handleSort('address')">
        <div class="truncate block relative flex items-center flex-start pr-6 font-medium">
          {{ $t('customer.fields.address') }}
          <ArrowSolidIcon v-if="selectedSortingKey === 'address'" :arrowUp="!arrowSortUp['address']" />
        </div>
      </div>
    </th>
    <th class="hidden xl:table-cell">
      <div class="flex justify-end cursor-pointer" @click="handleSort('contact')">
        <div class="relative flex items-center flex-start pr-6 font-medium">
          {{ $t('customer.fields.contact') }}
          <ArrowSolidIcon v-if="selectedSortingKey === 'contact'" :arrowUp="!arrowSortUp['contact']" />
        </div>
      </div>
    </th>
    <th class="whitespace-nowrap hidden xl:table-cell pr-4">
      <div class="flex justify-end w-full cursor-pointer" @click="handleSort('assignee_name')">
        <div class="relative flex items-center flex-start pr-6 font-medium">
          {{ $t('customer.fields.responsible') }}
          <ArrowSolidIcon v-if="selectedSortingKey === 'assignee_name'" :arrowUp="!arrowSortUp['assignee_name']" />
        </div>
      </div>
    </th>
    <th class="w-40p" />
  </tr>
  </thead>
</template>

<script>
import ArrowSolidIcon from '@/components/Icons/ArrowSolidIcon';

export default {
  name: 'TableHead',
  components: {
    ArrowSolidIcon
  },
  data() {
    return {
      arrowSortUp: {
        name: false,
        address: false,
        contact: false,
        assignee_name: false
      },
      selectedSortingKey: 'name'
    }
  },
  methods: {
    handleSort(key) {
      this.selectedSortingKey = key;
      this.$emit('handleSort', key, this.arrowSortUp[key]);
      this.arrowSortUp[key] = !this.arrowSortUp[key];
    }
  }
}
</script>

<style scoped>

</style>
