<template>
    <div v-if="!loading" class="mx-auto p-6 xl:max-w-full xl:grid xl:grid-cols-3 border-t border-b">
        <div class="xl:col-span-2 mb-8 xl:pr-8 xl:border-r xl:border-gray-200">
            <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div class="sm:col-span-1">
                    <FormInput
                        :label="$t('customer.fields.customerName')"
                        inputName="name"
                        :value="form.name"
                        :errorMessage="formErrors.name"
                        @handleInputChange="handleInputChange"
                    />
                </div>
                <div class="sm:col-span-1">
                    <FormInput
                        :label="$t('customer.fields.address')"
                        inputName="address"
                        :value="form.address"
                        @handleInputChange="handleInputChange"
                    />
                </div>
                <div class="sm:col-span-1">
                    <FormInput
                        :label="$t('customer.fields.contact')"
                        inputName="contact"
                        :value="form.contact"
                        @handleInputChange="handleInputChange"
                    />
                </div>
                <div class="sm:col-span-1">
                    <FormInput
                        type="text"
                        :label="$t('customer.fields.remoteId')"
                        inputName="invoicing_id"
                        :value="form.invoicing_id"
                        @handleInputChange="handleInputChange"
                    />
                </div>
                <div class="sm:col-span-2">
                    <FormTextAreaEditor
                        :label="$t('customer.fields.description')"
                        :value="form.description"
                        inputName="description"
                        @handleInputChange="handleInputChange"
                    />
                </div>
                <div class="sm:col-span-2">
                    <customer-apartments
                        :label="'Apartments'"
                        :apartments="form.apartments"
                        :editable="true"
                        :pageUpdateAction="pageUpdateAction"
                        @handleAddNewApartment="addNewApartment"
                        @handleEditApartment="editApartment"
                        @handleDeleteApartment="deleteApartment"
                    />
                </div>
                <div class="sm:col-span-2 border-t">
                    <CommentBox
                        v-if="pageUpdateAction"
                        :accountAvatar="account.avatar"
                        :authorName="account.name"
                        :customerId="form.id"
                        :comments="form.comments"
                        :prefix="prefix"
                    />
                </div>
            </div>
        </div>
        <aside class="xl:pl-8">
            <RightAside
                @handleChangeAssigneeUser="handleChangeAssigneeUser"
                @handleChangeAssigneeConnectorId="handleChangeAssigneeConnectorId"
                :editable="true"
                @handleAddAttachments="handleAddAttachments"
                @deleteAttachmentFile="deleteAttachmentFile"
                :form="form"
                :prefix="prefix"
                @deleteShareSpace="deleteShareSpace"
                @handleAddShareSpaces="addShareSpace"
                @updateShareSpace="updateShareSpace"
                :pageUpdateAction="pageUpdateAction"
            />
        </aside>
    </div>
</template>

<script>
import AssigneeUsers from '@/components/SelectOptions/AssigneeUsers/AssigneeUsers';
import AttachmentInputField from '@/components/Main/AttachmentFiles/AttachmentInputField/AttachmentInputField';
import DeleteAttachmentDropDown
    from '@/components/Main/AttachmentFiles/AttachmentInputField/DeleteAttachmentDropDown/DeleteAttachmentDropDown';
import ShareSpace from '@/components/Main/ShareSpace/ShareSpace';
import EditDeleteShareSpaceDropDown
    from '@/components/Main/ShareSpace/EditDeleteShareSpaceDropDown/EditDeleteShareSpaceDropDown';
import { mapActions, mapGetters } from 'vuex';
import DeleteItemModal from '@/components/Modals/DeleteItemModal/DeleteItemModal';
import CommentList from '@/components/Customer/CommentList/CommentList';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import FormInput from '@/components/Main/Form/FormInput/FormInput';
import { EventBus } from '@/eventBus/event-bus';
import FormTextArea from '@/components/Main/Form/FormTextArea/FormTextArea';
import CommentBox from '@/components/Main/CommentBox/CommentBox';
import RightAside from '@/components/Customer/RightAside/RightAside';
import { Attachments } from '@/mixins/Attachments';
import { RequestCustomerForm } from '@/mixins/Customer/RequestCustomerForm';
import { withShareSpace } from '@/mixins/Customer/withShareSpace';
import CustomerApartments from '@/components/Apartments/CustomerApartments';
import FormTextAreaEditor from '@/components/Main/Form/FormTextArea/FormTextAreaEditor';

export default {
    name: 'CreateOrUpdate',
    mixins: [Attachments, RequestCustomerForm, withShareSpace],
    components: {
        CustomerApartments,
        FormTextAreaEditor,
        RightAside,
        CommentBox,
        FormTextArea,
        CommentList,
        EditDeleteShareSpaceDropDown,
        ShareSpace,
        DeleteAttachmentDropDown,
        AttachmentInputField,
        AssigneeUsers,
        DeleteItemModal,
        ErrorMessage,
        FormInput
    },
    data() {
        return {
            loading: false,
            pageUpdateAction: false,
            prefix: 'customer',
            form: {
                id: '',
                name: '',
                contact: '',
                address: '',
                description: '',
                apartments: [],
                comments: [],
                assignee_id: '',
                connector_id: '',
                files: [],
                shareSpaces: [],
                invoicing_id: ''
            },
            formErrors: {
                name: []
            }
        };
    },
    computed: {
        ...mapGetters([
            'customer',
            'account',
            'updateCustomerSuccess',
            'updateCustomerError'
        ])
    },
    watch: {
        customer(data) {
            this.form = { ...data };
            this.loading = false;
            this.handleSetBreadCrumbItem(data.name);
        },
        updateCustomerSuccess(newValue) {
            newValue && this.redirectToShowPage();
        }
    },
    mounted() {
        this.handleSetBreadCrumbItems({ index: 0, to: '/customers' });
      this.handleSetBreadCrumbItems({ index: 1, to: '' });
        const { id } = this.$route.params;
        if (id) {
            this.loading = true;
            this.pageUpdateAction = true;
            this.handleGetCustomer(id);
        }
        EventBus.$on('handleSaveForm', () => this.handleSubmit());
    },
    methods: {
        ...mapActions({
            handleGetCustomer: 'getCustomer',
            handleCreateAttachmentFile: 'createAttachmentFile',
            handleSetBreadCrumbItem: 'setBreadCrumbItem',
            handleSetBreadCrumbItems: 'setBreadCrumbItems',
            handleResetBreadCrumbItems: 'resetBreadCrumbItems'
        }),
        handleCloseModal() {
            this.showModal = false;
        },
        handleInputChange(inputName, value) {
            this.formErrors[inputName] = [];
            this.form[inputName] = value;
        },
        handleChangeAssigneeUser(userId) {
            this.form.assignee_id = userId;
        },
        handleChangeAssigneeConnectorId(connectorId) {
            this.form.connector_id = connectorId;
        },
        handleSubmit() {
            this.pageUpdateAction ? this.updateCustomer() : this.createCustomer();
        },
        addNewApartment(event) {
            this.form.apartments.push({
                id: Math.round(Math.random() * 1000000),
                title: event
            });
        },
        editApartment(event) {
            this.form.apartments.find(apartment => apartment.id === event.id).title = event.title;
        },
        deleteApartment(event) {
            this.form.apartments = this.form.apartments.filter(apartment => apartment.id !== event);
        }
    },
    destroyed() {
        this.handleResetBreadCrumbItems();
        EventBus.$off('handleSaveForm');
        this.$destroy();
    }
};
</script>

<style scoped>

</style>
