import { makeFormData } from '@/helpers/formData';
import { mapActions, mapGetters } from 'vuex';

export const RequestCustomerForm = {
    computed: {
        ...mapGetters([
            'createCustomerSuccess',
            'createCustomerError',
            'updateCustomerError',
            'customerResponseMessage'
        ])
    },
    watch: {
        createCustomerSuccess(newValue) {
            if (newValue) {
                this.customerToastMessage(this.customerResponseMessage, 'success');
                this.redirectToShowPage();
            }
        },
        createCustomerError(newValue) {
            this.handleRequestErrorMessage(newValue);
        },
        updateCustomerError(newValue) {
            this.handleRequestErrorMessage(newValue);
        }
    },
    methods: {
        ...mapActions({
            handleCreateCustomer: 'createCustomer',
            handleUpdateCustomer: 'updateCustomer',
            fetchCreateApartment: 'addApartment',
            fetchUpdateApartment: 'updateApartment',
            fetchDelateApartment: 'deleteApartment'
        }),
        createRequestFormData(isFile) {
            return makeFormData({
                name: this.form.name,
                connector_id: this.form.connector_id,
                contact: this.form.contact,
                address: this.form.address,
                description: this.form.description,
                apartments: this.form.apartments.map(apartment => apartment.title),
                assignee_id: this.form.assignee_id,
                invoicing_id: this.form.invoicing_id,
                shareSpaces: this.form.shareSpaces,
                file: isFile ? this.form.files : null
            });
        },
        attachSharedSpacesToFormData(formData) {
            this.form.shareSpaces.filter((space, index) => {
                formData.append(`shareSpaces[${index}]`, JSON.stringify({ space: space.title, boards: space.boards }));
            });
        },
        createCustomer() {
            const formData = this.createRequestFormData(true);
            this.attachSharedSpacesToFormData(formData);
            this.handleCreateCustomer({ form: formData });
        },
        handleAddNewApartment(apartment) {
            this.fetchCreateApartment({ id:this.form.id, form: { apartment } }).then(({ data: { message, data } }) => {
            });
        },
        handleEditApartment(apartment) {
            this.fetchUpdateApartment({ id:this.form.id, apartment: { apartment } }).then(({ data: { message, data } }) => {
            });
        },
        handleDeleteApartment(apartment) {
            this.fetchDelateApartment({ id:this.form.id, apartmentId: apartment }).then(({ data: { message, data } }) => {
            });
        },
        updateCustomer() {
            const formData = this.createRequestFormData(false);
            formData.append('_method', 'PATCH');
            this.handleUpdateCustomer({ form: formData, id: this.form.id });
        },
        handleRequestErrorMessage(isError) {
            if (isError) {
                const { message, errors } = this.customerResponseMessage;
                this.formErrors = { ...this.formErrors, ...errors };
                this.customerToastMessage(message, 'error');
            }
        },
        redirectToShowPage() {
            this.$router.push({ name: 'customer-show', params: { id: this.customer.id } });
        },
        customerToastMessage(message, type) {
            this.$toast.clear();
            this.$toast.open({ message, type })
        }
    }
}
