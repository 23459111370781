<template>
  <AppLayout>
    <router-view />
  </AppLayout>
</template>

<script>
import AppLayout from '@/components/Layout/AppLayout';

export default {
  name: 'Customers',
  components: {
    AppLayout
  }
}
</script>

<style scoped>

</style>
