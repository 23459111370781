<template>
    <div v-if="!loading" class="mx-auto p-6 xl:max-w-full xl:grid xl:grid-cols-3 border-t border-b">
        <div class="xl:col-span-2  mb-8 xl:pr-8 xl:border-r xl:border-gray-200">
            <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div class="sm:col-span-1">
                    <div v-if="!editable" class="fieldTitle">
                        <MainFieldTitle :label="$t('customer.fields.customerName')" :name="form.name"/>
                    </div>
                    <FormInput
                        v-else
                        :label="$t('customer.fields.customerName')"
                        inputName="name"
                        :value="form.name"
                        :errorMessage="formErrors.name"
                        @handleInputChange="handleInputChange"
                    />
                </div>
                <div class="sm:col-span-1">
                    <div v-if="!editable" class="fieldTitle">
                        <MainFieldTitle :label="$t('customer.fields.address')" :name="form.address"/>
                    </div>
                    <FormInput
                        v-else
                        :label="$t('customer.fields.address')"
                        inputName="address"
                        :value="form.address"
                        @handleInputChange="handleInputChange"
                    />
                </div>
                <div class="sm:col-span-1">
                    <div v-if="!editable" class="fieldTitle">
                        <MainFieldTitle :label="$t('customer.fields.contact')" :name="form.contact"/>
                    </div>
                    <FormInput
                        v-else
                        :label="$t('customer.fields.contact')"
                        inputName="contact"
                        :value="form.contact"
                        @handleInputChange="handleInputChange"
                    />
                </div>
                <div class="sm:col-span-1">
                    <div v-if="!editable" class="fieldTitle">
                        <MainFieldTitle :label="$t('customer.remoteId')" :name="form.invoicing_id"/>
                    </div>
                    <FormInput
                        v-else
                        type="text"
                        :label="$t('customer.fields.remoteId')"
                        inputName="invoicing_id"
                        :value="form.invoicing_id"
                        @handleInputChange="handleInputChange"
                    />
                </div>
                <div class="sm:col-span-2">
                    <FormTextAreaEditor :label="$t('customer.fields.description')"
                                        :value="form.description"
                                        inputName="description"
                                        :disabled="!editable"
                                        @handleInputChange="handleInputChange"/>
                </div>

                <div class="sm:col-span-2">
                    <customer-apartments
                        :label="'Apartments'"
                        :apartments="form.apartments"
                        :editable="editable"
                        :pageUpdateAction="pageUpdateAction"
                        @handleAddNewApartment="handleAddNewApartment"
                        @handleEditApartment="handleEditApartment"
                        @handleDeleteApartment="handleDeleteApartment"
                    />
                </div>

                <div class="sm:col-span-2 border-t">
                    <CommentBox
                        :accountAvatar="account.avatar"
                        :authorName="account.name"
                        :customerId="form.id"
                        :ownerId="form.id"
                        :comments="form.comments"
                        :prefix="prefix"
                    />
                </div>
            </div>
        </div>
        <aside class="xl:pl-8">
            <RightAside
                @handleChangeAssigneeUser="handleChangeAssigneeUser"
                :editable="editable"
                @handleAddAttachments="handleAddAttachment"
                @handleChangeAssigneeConnectorId="handleChangeAssigneeConnectorId"
                @deleteAttachmentFile="deleteAttachmentFile"
                :form="form"
                :prefix="prefix"
                @deleteShareSpace="deleteShareSpace"
                @handleAddShareSpaces="addShareSpace"
                @updateShareSpace="updateShareSpace"
            />
        </aside>
    </div>
</template>

<script>
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage';
import { mapActions, mapGetters } from 'vuex';
import { EventBus } from '@/eventBus/event-bus';
import FormInput from '@/components/Main/Form/FormInput/FormInput';
import CommentList from '@/components/Customer/CommentList/CommentList';
import MainFieldTitle from '@/components/Main/Form/MainFieldTitle/MainFieldTitle';
import FormTextArea from '@/components/Main/Form/FormTextArea/FormTextArea';
import CommentBox from '@/components/Main/CommentBox/CommentBox';
import RightAside from '@/components/Customer/RightAside/RightAside';
import CustomerApartments from '@/components/Apartments/CustomerApartments';
import { Attachments } from '@/mixins/Attachments';
import { RequestCustomerForm } from '@/mixins/Customer/RequestCustomerForm';
import { withShareSpace } from '@/mixins/Customer/withShareSpace';
import FormTextAreaEditor from '@/components/Main/Form/FormTextArea/FormTextAreaEditor';

export default {
    name: 'Customer',
    mixins: [Attachments, RequestCustomerForm, withShareSpace],
    components: {
        FormTextAreaEditor,
        RightAside,
        CommentBox,
        FormTextArea,
        MainFieldTitle,
        ErrorMessage,
        FormInput,
        CommentList,
        CustomerApartments
    },
    data() {
        return {
            editable: false,
            loading: true,
            pageUpdateAction: true,
            prefix: 'customer',
            form: {
                id: '',
                name: '',
                contact: '',
                address: '',
                description: '',
                apartments: [],
                comments: [],
                assignee_id: '',
                connector_id: '',
                files: [],
                shareSpaces: [],
                invoicing_id: ''
            },
            attachedFiles: [],
            formErrors: {}
        };
    },
    computed: {
        ...mapGetters([
            'account',
            'customer',
            'updateCustomerSuccess',
            'customerResponseMessage'
        ])
    },
    watch: {
        customer(data) {
            this.form = { ...data };
            this.handleSetBreadCrumbItem(data.name);
            this.loading = false;
        },
        updateCustomerSuccess() {
            this.editable = false;
            EventBus.$emit('formSubmitted', true);
            this.customerToastMessage(this.customerResponseMessage, 'success');
            this.handleSetBreadCrumbItem(this.form.name);
        }
    },
    mounted() {
      this.handleSetBreadCrumbItems({ index: 0, to: '/customers' });
        const { id } = this.$route.params;
        this.handleGetCustomer(id);
        this.form.id = id;
        EventBus.$on('handleEdit', editable => {
            this.form = { ...this.customer };
            this.editable = editable;
            this.formErrors = {};
        });
        EventBus.$on('handleSave', () => this.updateCustomer());
    },
    methods: {
        ...mapActions({
            handleGetCustomer: 'getCustomer',
            handleSetBreadCrumbItem: 'setBreadCrumbItem',
            handleSetBreadCrumbItems: 'setBreadCrumbItems',
            handleResetBreadCrumbItems: 'resetBreadCrumbItems'
        }),
        handleInputChange(inputName, value) {
            this.formErrors[inputName] = [];
            this.form[inputName] = value;
        },
        handleChangeAssigneeUser(userId) {
            this.form.assignee_id = userId;
        },
      handleChangeAssigneeConnectorId(connectorId) {
        this.form.connector_id = connectorId;
      },
        handleAddAttachment(files) {
            this.attachedFiles = files;
            this.handleAddAttachments(files);
        }
    },
    destroyed() {
        this.handleResetBreadCrumbItems();
        EventBus.$off('handleSave');
        this.handleSetBreadCrumbItem();
        this.$toast.clear();
        this.$destroy();
    }
};
</script>

<style scoped>

</style>
